body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Open Sans',
    Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hero-image {
  transition: transform 0.3s ease-in-out;
}

.hero-image:hover {
  transform: scale(1.05);
}

.icon-right {
  transition: transform 0.3s ease-in-out;
}
.icon-left {
  transition: transform 0.3s ease-in-out;
}

.icon-down {
  transition: transform 0.3s ease-in-out;
}

.icon-bottom {
  transition: transform 0.3s ease-in-out;
}

.relative:hover .icon-right {
  transform: translate(10px, -10px);
}

.relative:hover .icon-left {
  transform: translate(-10px, -10px);
}

.relative:hover .icon-down {
  transform: translate(10px, 10px);
}

.relative:hover .icon-bottom {
  transform: translate(-10px, 10px);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


@keyframes radiate {
  0% {
    width: 0;
    opacity: 0;
  }
  25% {
    width: 25%;
    opacity: 1;
  }
  50% {
    width: 50%;
    opacity: 1;
  }
  75% {
    width: 75%;
    opacity: 1;
  }
  100% {
    width: 100%;
    opacity: 1;
  }
}

@keyframes ping {
  0% {
      transform: scale(0.8);
      opacity: 0.5;
  }
  70% {
      transform: scale(1.2);
      opacity: 0;
  }
  100% {
      opacity: 0;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-up {
  animation: slideUp 0.5s ease-out forwards;
}


@media (min-width: 470px) and (max-width: 639.5px) {
  div.uploadvideo {
    width: 54%;
  }

  div.addMoreFiles {
    width: 48%;
  }

  span.uploaded {
    /* font-size: 1.125rem; */
    line-height: 1.75rem;
  }
}

@media (min-width: 390px) and (max-width: 469.5px) {
  div.uploadvideos {
    width: 70%;
  }

  span.uploads {
    /* font-size: 1.125rem; */
    line-height: 1.75rem;
  }
}


@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
